import React, { useState } from "react";
import cx from "classnames";
import { useForm } from "react-hook-form";
import { AuthLayout } from "../AuthLayouts";
import {
  fetchWithoutAuth,
  FormErrorData,
  UserSuccessData,
  getTenancy,
  Tenancy,
} from "../../../api-client/APIClient";
import { SignUpUserData } from "./SignUpScreen";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { PRIVACY_POLICY_PATH, SUBSCRIBER_AGREEMENT_PATH } from "../../../router/RouterUtils";
import { showErrorToast } from "../../shared-components/Toasts";

import {
  STANDARD_SIGNUP_FORM_ID,
  INVITE_SIGNUP_FORM_ID,
  EU_STANDARD_SIGNUP_FORM_ID,
  EU_INVITE_SIGNUP_FORM_ID,
} from "./HubspotForms";
import { Alert, Form } from "react-bootstrap";

interface FormData {
  name: string;
  organization: string;
  agreed_to_terms_of_service_and_read_privacy_policy: boolean;
}

interface Props {
  email: string;
  handleSuccessfulSignUp: (data: UserSuccessData, formId: string) => void;
  idToken: string;
  inviteToken?: string | null;
  name?: string | null;
  organizationName?: string | null;
  socialAuthProvider: string;
}
const FinishSocialSignUp = ({
  email,
  handleSuccessfulSignUp,
  idToken,
  inviteToken,
  name,
  organizationName,
  socialAuthProvider,
}: Props) => {
  const { errors, handleSubmit, register, setError } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isEUMultiTenant = getTenancy() === Tenancy.EUMultiTenant;
  let signupFormGTag = "";
  let createAccountButtonGTag = "";
  let hubspotFormID = "";
  if (isEUMultiTenant) {
    if (inviteToken) {
      signupFormGTag = "gtag-invite-form_eu";
      createAccountButtonGTag = "gtag-create-account-invite_eu";
      hubspotFormID = EU_INVITE_SIGNUP_FORM_ID;
    } else {
      signupFormGTag = "gtag-signup-form_eu";
      createAccountButtonGTag = `gtag-create-account-social-auth-${socialAuthProvider}_eu`;
      hubspotFormID = EU_STANDARD_SIGNUP_FORM_ID;
    }
  } else {
    if (inviteToken) {
      signupFormGTag = "gtag-invite-form";
      createAccountButtonGTag = "gtag-create-account-invite";
      hubspotFormID = INVITE_SIGNUP_FORM_ID;
    } else {
      signupFormGTag = "gtag-signup-form";
      createAccountButtonGTag = `gtag-create-account-social-auth-${socialAuthProvider}`;
      hubspotFormID = STANDARD_SIGNUP_FORM_ID;
    }
  }
  const performSignUp = (data: FormData) => {
    const userInfo: SignUpUserData = {
      name: data.name,
      organization: data.organization,
      email: email,
      social_auth_provider: socialAuthProvider,
      agreed_to_terms_of_service_and_read_privacy_policy:
        data.agreed_to_terms_of_service_and_read_privacy_policy,
      id_token: idToken,
    };
    if (inviteToken) {
      userInfo.email_token = inviteToken;
    }

    fetchWithoutAuth({
      path: "/users/create/social",
      method: "POST",
      body: userInfo,
      onResponse: (data: UserSuccessData) => {
        handleSuccessfulSignUp(data, hubspotFormID);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                setErrorMessage(data[field_name][0]);
                continue;
              }
              setError(field_name, { message: data[field_name][0] });
            }
          });
        } else {
          showErrorToast("A network error has occurred. Please try again.");
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <AuthLayout title="Set up your account" description="Add your profile details below">
      <Form onSubmit={handleSubmit(performSignUp)} id={signupFormGTag}>
        {errorMessage && (
          <Form.Group>
            <Alert variant="dark">{errorMessage}</Alert>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label htmlFor="name">Full Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            id="name"
            placeholder="Full name"
            className={cx({ "is-invalid": errors.name })}
            ref={register({
              required: true,
            })}
            defaultValue={name || ""}
            readOnly={!!name}
          />
          <Form.Control.Feedback type="invalid">Please enter a name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="company">
          <Form.Label htmlFor="organization">Company</Form.Label>
          <Form.Control
            name="organization"
            id="organization"
            type="text"
            placeholder="Company"
            className={cx({
              "is-invalid": errors.organization,
            })}
            ref={register({
              required: true,
            })}
            defaultValue={organizationName || ""}
            readOnly={!!inviteToken}
          />
          <Form.Control.Feedback type="invalid">Please enter a company name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Check>
            <Form.Check.Input
              name="agreed_to_terms_of_service_and_read_privacy_policy"
              id="agreed_to_terms_of_service_and_read_privacy_policy"
              type="checkbox"
              className={cx({
                "is-invalid": errors.agreed_to_terms_of_service_and_read_privacy_policy,
              })}
              ref={register({
                required: true,
              })}
            />
            <Form.Check.Label
              className="small"
              htmlFor="agreed_to_terms_of_service_and_read_privacy_policy"
            >
              I agree to the{" "}
              <a
                className="font-medium"
                href={SUBSCRIBER_AGREEMENT_PATH}
                target="_blank"
                rel="noreferrer"
              >
                Subscriber Agreement
              </a>{" "}
              and have read the{" "}
              <a
                className="font-medium"
                href={PRIVACY_POLICY_PATH}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid">
              Please agree to the Subscriber Agreement and read the Privacy Policy.
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
        <Form.Group>
          <SpinnerButton
            text="Next"
            isLoading={isLoading}
            className="primaryAction btn btn-primary btn-block"
            id={createAccountButtonGTag}
          />
        </Form.Group>
      </Form>
    </AuthLayout>
  );
};

export default FinishSocialSignUp;
