import styled, { css } from "styled-components";
import { palette } from "../../../styles/theme";
import SearchDropdownItem from "./SearchDropdownItem";

interface Props {
  /**
   * If there's a "mouse over" type hover effect currently applied
   */
  isHighlighted: boolean;

  /**
   * If the checkbox is currently selected
   */
  isSelected: boolean;
}

const PADDING = css`
  padding: 7px 12px;
`;

/**
 * One checkable dropdown item, with some text, with text that's blue if
 * highlighted.
 */
const SearchDropdownCheckboxItem = styled(SearchDropdownItem).attrs({
  className: "d-flex align-items-center",
})<Props>`
  gap: 12px;
  position: relative;
  ${PADDING};
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    border: 1px solid ${palette.blue};
    box-sizing: border-box;
    border-radius: 4px;
    ${({ isSelected }) =>
      isSelected &&
      css`
        background: ${palette.blue};
      `}
  }
  /* A simple checkmark, centered, only if it's selected (checked) */
  ${({ isSelected }) =>
    isSelected &&
    css`
      &:after {
        z-index: 1;
        ${PADDING};
        content: url('data:image/svg+xml; utf8, <svg width="10" height="8"  viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.60156 3.5L4.10156 6L8.60156 1.5" stroke="white" stroke-width="2"/></svg>');
        display: block;
        width: 10px;
        height: 8px;
        position: absolute;
        left: 3px;
        top: 0;
      }
    `}
`;

export default SearchDropdownCheckboxItem;
