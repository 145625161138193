import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { palette } from "../../../styles/theme";

interface Props {
  /**
   * This component sizes itself dynamically to match the width of the element
   * this ref points to.
   */
  widthRef: React.RefObject<HTMLDivElement>;
}

/**
 * To achieve a look of continuous shadow with two elements (this and
 * `SearchBar`), we use clip-path to break each shadow where the elements
 * touch. Even though the shadow is the same, they look different at the
 * breakpoint since the shadow is shifted in the y axis. We therefore shift
 * this element up and pad it appropriately to minimize the visual cutoff
 * at the shadow boundary. This value was chosen visually!
 */
export const OVERLAP = 12;

const OVERLAP_PX = `${OVERLAP}px`;

// Intentionally cut off the top of the shadow so it doesn't bleed onto the edit box above, and adds space for the shadow on other edges. Bigger max height than default
const Container = styled.div`
  border: none;
  background: ${palette.white};
  border-radius: 0 0 6px 6px;
  margin-top: -${OVERLAP_PX};
  padding: ${OVERLAP_PX} 0 6px 0;
  outline: none;
  box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
  clip-path: polygon(-100% ${OVERLAP_PX}, -100% 200%, 200% 200%, 200% ${OVERLAP_PX});
  max-height: calc(min(856px, 80vh - 48px));
`;

/**
 * Shows the dropdown menu that comes out of the whole search bar, with
 * sections for recent, saved, and all filters. Those sections should be
 * passed via `children`.
 */
const SearchBarDropdownMenu = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div"> & Props
>(({ widthRef, style, children, ...props }, ref) => {
  const [width, setWidth] = useState(0);

  // Resizes the element when the widthRef changes, and sets up a resize observer to also update when the parent bounds change
  useEffect(() => {
    const updateWidth = () => {
      if (!widthRef.current) {
        return;
      }
      setWidth(widthRef.current.getBoundingClientRect().width);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [widthRef]);

  return (
    <Container {...props} tabIndex={0} role="menu" ref={ref} style={{ ...style, width }}>
      {children}
    </Container>
  );
});

export default SearchBarDropdownMenu;
