import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import {
  fetchWithoutAuth,
  setAuthTokenAndUserType,
  setUserType,
} from "../../../api-client/APIClient";
import { LOGIN_PATH } from "../../../router/RouterUtils";

interface UserSAMLSuccessData {
  user_type: string;
}

/**
 * The SSO with SAML flow leads to the backend /api/users/saml/relay route,
 * which will redirect to this screen with query params "token" and "user_type".
 * This component parses those params, sets up auth for the user, and redirects to "/".
 */
const SamlRedirectScreen = () => {
  // loading is needed for proper routing w new fetch statement
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const userType = params.get("user_type");
  const userId = params.get("user_id");

  if (!token) {
    return <Redirect to={LOGIN_PATH} />;
  }

  if (!userType) {
    if (!userId) {
      return <Redirect to={LOGIN_PATH} />;
    }
    if (!requestSent) {
      fetchWithoutAuth({
        path: "/users/sso/saml/relay-auth-token",
        method: "POST",
        body: { user_id: userId, token_key: token },
        onResponse: (data: UserSAMLSuccessData) => {
          if (data.user_type) {
            setUserType(data.user_type);
          }
          setIsLoading(false);
        },
        onError: (_err: Response | undefined) => {
          // will redirect to router and auto redirect back to login page since no auth token will be found
          setIsLoading(false);
        },
      });
      setRequestSent(true);
    }

    if (!isLoading) {
      return <Redirect to="/" />;
    } else {
      return <></>;
    }
  } else {
    setAuthTokenAndUserType(token, userType);
    return <Redirect to="/" />;
  }
};

export default SamlRedirectScreen;
