import React, { useEffect, useState } from "react";
import { OrganizationIntegrationSettings } from "../../../models/Entities";
import SortableIntegrationContainer from "./SortableIntegrationContainer";

interface Props {
  category: string;
  organizationIntegrationsSettings: Array<OrganizationIntegrationSettings>;
}

const ConfigurationIntegrationsList = ({ category, organizationIntegrationsSettings }: Props) => {
  const [categoryOrganizationIntegrationsSettings, setCategoryOrganizationIntegrationsSettings] =
    useState<Array<OrganizationIntegrationSettings>>();

  useEffect(() => {
    // Init the categoryOrganizationIntegrationsSettings from higher order full list
    // by filtering via category
    if (organizationIntegrationsSettings) {
      setCategoryOrganizationIntegrationsSettings(
        organizationIntegrationsSettings.filter(
          (orgIntegration) => orgIntegration.category == category,
        ),
      );
    }
  }, [organizationIntegrationsSettings]);

  const onIntegrationUpdate = (
    updatedOrgIntegration: OrganizationIntegrationSettings,
    currDrawerList: Array<OrganizationIntegrationSettings>,
  ) => {
    // Update the Integration after a PATCH call
    // Sorting is done on the currDrawerList to maintain display order in SortableContainer
    const index = currDrawerList?.findIndex(
      (orgIntegration) => orgIntegration.id === updatedOrgIntegration.id,
    );
    const newIntegrationList = [
      ...currDrawerList.slice(0, index),
      updatedOrgIntegration,
      ...currDrawerList.slice(index + 1),
    ];
    setCategoryOrganizationIntegrationsSettings(newIntegrationList);
  };

  return (
    <>
      {categoryOrganizationIntegrationsSettings && (
        <SortableIntegrationContainer
          organizationIntegrationsSettings={categoryOrganizationIntegrationsSettings}
          category={category}
          onIntegrationUpdate={onIntegrationUpdate}
        />
      )}
    </>
  );
};

export default ConfigurationIntegrationsList;
