/// /////////////////////////////
/// / Blueprint JSON Parsing ////
/// /////////////////////////////

export const STEP_TYPES = {
  API_REQUEST: "API_REQUEST",
  ARRAY_LOOP: "ARRAY_LOOP",
  IF_ELSE: "IF_ELSE",
  CREATE_OR_UPDATE: "CREATE_OR_UPDATE",
};

export const PUBLISH_STATES = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  DRAFT: "DRAFT",
};

/// /////////////////////////////

/// /////////////////////////////
/// /// HRIS Common Models //////
/// /////////////////////////////

export const HRIS_COMMON_MODEL_TYPES = {
  EMPLOYEE: "EMPLOYEE",
  LOCATION: "LOCATION",
  COMPANY: "COMPANY",
  EMPLOYMENT: "EMPLOYMENT",
  TEAM: "TEAM",
  TIME_OFF: "TIME_OFF",
  BENEFIT: "BENEFIT",
  DOCUMENT: "DOCUMENT",
  REPORT: "REPORT",
  PAYROLL_RUN: "PAYROLL_RUN",
  EMPLOYEE_PAYROLL_RUN: "EMPLOYEE_PAYROLL_RUN",
  TIME_OFF_BALANCE: "TIME_OFF_BALANCE",
};

/// /////////////////////////////
/// /// ATS Common Models //////
/// /////////////////////////////

export const ATS_COMMON_MODEL_TYPES = {
  REMOTE_USER: "REMOTE_USER",
  JOB: "JOB",
  DEPARTMENT: "DEPARTMENT",
  OFFICE: "OFFICE",
  CANDIDATE: "CANDIDATE",
  TAG: "TAG",
  APPLICATION: "APPLICATION",
  JOB_INTERVIEW_STAGE: "JOB_INTERVIEW_STAGE",
  SCHEDULED_INTERVIEW: "SCHEDULED_INTERVIEW",
  SCORECARD: "SCORECARD",
  REJECTION_REASON: "REJECTION_REASON",
  ATTACHMENT: "ATTACHMENT",
  OFFER: "OFFER",
  EEOC: "EEOC",
  ACTIVITY: "ACTIVITY",
};

/// /////////////////////////////
/// /// ACCOUNTING Common Models //////
/// /////////////////////////////

export const ACCOUNTING_COMMON_MODEL_TYPES = {
  ACCOUNT: "ACCOUNT",
  ACCOUNTING_ATTACHMENT: "ACCOUNTING_ATTACHMENT",
  BALANCE_SHEET: "BALANCE_SHEET",
  CASH_FLOW_STATEMENT: "CASH_FLOW_STATEMENT",
  COMPANYINFO: "COMPANY_INFO",
  CONTACT: "CONTACT",
  ACCOUNTING_PHONE_NUMBER: "ACCOUNTING_PHONE_NUMBER",
  ADDRESS: "ADDRESS",
  INCOMESTATEMENT: "INCOME_STATEMENT",
  REPORT_ITEM: "REPORT_ITEM",
  CREDIT_NOTE: "CREDIT_NOTE",
  ITEM: "ITEM",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  PURCHASE_ORDER_LINE_ITEM: "PURCHASE_ORDER_LINE_ITEM",
  TRACKING_CATEGORY: "TRACKING_CATEGORY",
  JOURNAL_ENTRY: "JOURNAL_ENTRY",
  JOURNAL_LINE: "JOURNAL_LINE",
  TAX_RATE: "TAX_RATE",
  INVOICE: "INVOICE",
  INVOICE_LINE_ITEM: "INVOICE_LINE_ITEM",
  PAYMENT: "PAYMENT",
};

/// /////////////////////////////
/// /// CUSTOM OBJECT Common Model Names //////
/// /////////////////////////////
export const CUSTOM_OBJECT_COMMON_MODEL_NAMES = [
  "CustomObjectClass",
  "CustomObject",
  "Association",
  "AssociationSubType",
  "AssociationType",
];

/// /////////////////////////////

/// /////////////////////////////
/// /         Time           ////
/// /////////////////////////////
export const SEC_IN_YEAR = 60 * 60 * 24 * 365;
export const SEC_IN_DAY = 60 * 60 * 24;

export const SEC_PASSWORD_RESET_AUTO_REDIRECT = 15;

/// /////////////////////////////

/// /////////////////////////////
/// /      Documentation      ////
/// /////////////////////////////
export const DOCUMENTATION_ORDER = [
  "link-token",
  "account-token",
  "regenerate-key",
  "available-actions",
  "passthrough",
];

/// /////////////////////////////

/// /////////////////////////////
/// /             Logs       ////
/// /////////////////////////////

export const LOG_TYPES = {
  API_REQUEST_LOG_ENTRY_TYPE: "APIRequestLogEntry",
};

export const LOG_STATUSES = {
  REQUEST_STATUS_SUCCEEDED: "SUCCEEDED",
  REQUEST_STATUS_FAILED: "FAILED",
};

export const LOG_DIRECTIONS = {
  LOG_INBOUND: "INBOUND",
  LOG_OUTBOUND: "OUTBOUND",
};

export const REDACTED_VALUE_TEXT = "<redacted>";

/// /////////////////////////////

/// /////////////////////////////
/// /       Issues           ////
/// /////////////////////////////

export const ISSUE_STATUSES = {
  ISSUE_STATUS_ONGOING: "ONGOING",
  ISSUE_STATUS_RESOLVED: "RESOLVED",
};

export enum IssueDescription {
  INVALID_LOGIN_CREDENTIALS = "Invalid Login Credentials",
  MISSING_PERMISSION = "Missing Permission",
  BAD_API_KEY = "Bad API Key",
  HIT_RATE_LIMIT = "Hit Rate Limit",
  EXPIRED_MFA_AUTHENTICATION = "Expired Multi-factor Authentication",
}

/// /////////////////////////////

/// /////////////////////////////
/// /     Date Options       ////
/// /////////////////////////////

export enum DATE_PICKER_OPTIONS {
  ALL_TIME = "All time",
  PAST_24_HOURS = "Past 24 hours",
  PAST_WEEK = "Past week",
  PAST_MONTH = "Past month",
  PAST_YEAR = "Past year",
  CUSTOM = "Custom...",
}

// Options to use for date formatting
export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
  hour12: true,
};

export enum FieldMappingSource {
  LINKED_ACCOUNT = "LINKED_ACCOUNT",
  ORGANIZATION = "ORGANIZATION",
}

export const INTEGRATION_WIDE_FIELD_MAPPING_ENABLED_FLAG =
  "is_integration_wide_field_mappings_enabled";
export const LinkedAccountStatuses = {
  ALL: "ALL",
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE",
  RELINK_NEEDED: "RELINK_NEEDED",
};

export const ScopeStatuses = {
  READ: "Read",
  READ_WRITE: "R + W",
  WRITE: "Write",
  DISABLED: "Disabled",
};

export const ISSUE_CREATED = "Issue Created";
export const ISSUE_RESOLVED = "Issue Resolved";
export const ISSUE_SYSTEM_V2 = "ISSUE_SYSTEM_V2";

export const MENDABLE_ANON_KEY = "d05418d3-29b3-4942-b03b-51f05446823e";
