import useAppContext from "../../context/useAppContext";
import { OrganizationBillingPlan, ProductRestrictions } from "../../settings/billing/BillingModels";

const useProductRestrictions = (): {
  productRestrictions?: ProductRestrictions;
  orgBillingPlan?: OrganizationBillingPlan;
} => {
  const { user } = useAppContext();

  // Normally, an Organization does not contain a reference to an OrganizationBillingPlan,
  // and the OrganizationBillingPlan has a reference to the Organization. In the AppContext, that is flipped:
  // AppContext.user.organization contains organization_billing_plan. This transforms the data to the original schema:
  // OrganizationBillingPlan contains an Organization, instead of the other way around.
  const { organization_billing_plan: orgBillingPlanWithoutOrganization, ...orgWithoutBillingPlan } =
    user.organization;

  const orgBillingPlan =
    orgBillingPlanWithoutOrganization === undefined
      ? undefined
      : {
          ...orgBillingPlanWithoutOrganization,
          organization: orgWithoutBillingPlan,
        };

  return {
    productRestrictions: orgBillingPlan?.product_restrictions,
    orgBillingPlan,
  };
};

export default useProductRestrictions;
