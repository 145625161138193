import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import { numberWithCommas } from "../../../services";
import { palette } from "../../../styles/theme";

interface Props {
  data?: Array<{ date: string; request_count: number }>;
}

export function APIRequestsChart({ data }: Props) {
  const dateFormat = "MMM dd";
  const [displayChartData, setDisplayChartData] = useState<
    { date: string; "api-requests": number }[] | undefined
  >();

  useEffect(() => {
    setDisplayChartData(
      data?.map((item) => {
        return {
          date: format(new Date(item["date"].substring(0, 10)), dateFormat),
          "api-requests": item["request_count"],
        };
      }),
    );
  }, [data]);

  function getTablePaddingBasedOnValues() {
    if (displayChartData == undefined) {
      return 0;
    }
    // Calculate table left margin based on max value
    const maxChartValue = Math.max.apply(
      Math,
      displayChartData.map(function (o) {
        return o["api-requests"];
      }),
    );
    if (maxChartValue < 9800) {
      return -25;
    } else if (maxChartValue < 98000) {
      return -15;
    } else if (maxChartValue < 980000) {
      return -10;
    } else {
      return 0;
    }
  }

  const leftSideMargin = getTablePaddingBasedOnValues();

  return (
    <>
      {displayChartData ? (
        <ResponsiveContainer height="100%" width="100%">
          <AreaChart
            data={displayChartData}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: leftSideMargin,
            }}
          >
            <defs>
              <linearGradient id="api-requests" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={palette.blue} stopOpacity={0.12} />
                <stop offset="95%" stopColor={palette.blue} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              vertical={false}
              stroke={palette.gray}
              strokeDasharray="2 2"
              strokeWidth="0.2"
            />
            <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fill: palette.gray }} />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: palette.gray }}
              tickFormatter={(value) => numberWithCommas(value)}
              width={75}
            />
            <Area
              type="monotone"
              dataKey="api-requests"
              dot={false}
              stroke={palette.blue}
              strokeWidth={2.5}
              fillOpacity={1}
              fill="url(#api-requests)"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer height="100%" width="100%">
          <LineChart
            data={emptyStateData}
            margin={{
              top: 0,
              right: 2,
              bottom: 0,
              left: -25,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke={palette.gray}
              strokeDasharray="2 2"
              strokeWidth="0.2"
            />
            <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fill: palette.gray }} />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: palette.gray }} />
            <Line
              type="monotone"
              dataKey="api-requests"
              dot={false}
              stroke="#b0b1b4"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

const emptyStateData = [
  {
    date: "-",
    "api-requests": 0,
  },
  {
    date: "-",
    "api-requests": 0,
  },
];

export default APIRequestsChart;
