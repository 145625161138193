import styled, { css } from "styled-components";
import { palette, spectrum } from "../../../styles/theme";

interface Props {
  /**
   * If there's a "mouse over" type hover effect currently applied
   */
  isHighlighted: boolean;

  /**
   * If this is the option currently picked
   */
  isSelected: boolean;
}

const ACTIVE_STYLES = css`
  &&& {
    background: ${spectrum.blue0};
    color: ${palette.blue};
  }
`;

/**
 * One plain dropdown item, text based, with a background that's light blue
 * if highlighted.
 */
const SearchDropdownItem = styled.a.attrs({ tabIndex: 0 })<Props>`
  cursor: pointer;
  display: block;
  padding: 6px 12px;
  background: ${palette.white};
  color: ${palette.black};
  ${({ isHighlighted }) => isHighlighted && ACTIVE_STYLES}
  ${({ isSelected }) =>
    isSelected &&
    css`
      && {
        color: ${palette.blue};
      }
    `}
`;

export default SearchDropdownItem;
