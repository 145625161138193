import React from "react";
import styled from "styled-components";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

export interface TileWithImageProps {
  img: React.ReactElement;
  title: string;
  subtitle1?: JSX.Element;
  subtitle2?: JSX.Element;
  subtitle3?: JSX.Element;
}

//used for the header in each of the individual step sections
const TitleText = styled(DeprecatedH5)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111317;
  padding: 16px 20px;
  margin-bottom: 0px;
`;

const SubTitleText = styled(DeprecatedH6)`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: background: rgba(17, 19, 23, 1);
  padding: 0px 20px;
  margin-bottom: 0px;
`;

// used for the primary description container
const Container = styled.div`
  background: rgba(242, 244, 255, 1);
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  padding: 16px 20px 16px 20px;
`;

const Image = styled.i`
  margin-right: 12px;
`;

const TileOption = ({ img, title, subtitle1, subtitle2, subtitle3 }: TileWithImageProps) => (
  <Container>
    <TitleText>
      <Image>{img}</Image>
      {title}
    </TitleText>
    <SubTitleText>{subtitle1}</SubTitleText>
    <SubTitleText>{subtitle2}</SubTitleText>
    <SubTitleText>{subtitle3}</SubTitleText>
  </Container>
);

export default TileOption;
