import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { User } from "../../models/Entities";
import MergeMark from "../../assets/img/mark-borderless.png";

export enum UserAvatarSize {
  xxl = "avatar-xxl",
  xl = "avatar-xl",
  normal = "",
  sm = "avatar-sm",
  xs = "avatar-xs",
}

interface UserAvatarProps {
  user?: User;
  email?: string;
  size?: UserAvatarSize;
  className?: string;
}

export const UserAvatar = ({
  user,
  email,
  size = UserAvatarSize.normal,
  className = "",
}: UserAvatarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [avatarLetters, setAvatarLetters] = useState("");

  useEffect(() => {
    setWidth(containerRef.current?.offsetWidth ?? 0);
    setHeight(containerRef.current?.offsetHeight ?? 0);
  }, [containerRef]);

  useMemo(() => {
    if (user) {
      if (user.name) {
        const splitName = user.name.split(" ");
        if (splitName.length > 0) {
          setAvatarLetters(splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0));
        }
      } else if (user.email) {
        setAvatarLetters(user.email.charAt(0).toUpperCase());
      }
    } else if (email) {
      setAvatarLetters(email.charAt(0).toUpperCase());
    }
  }, [user, email]);

  return user?.avatar ? (
    <div className={"avatar " + size + " " + className}>
      <img src={user?.avatar} className="avatar-img rounded-circle" />
    </div>
  ) : (
    <div className={"avatar " + size + " " + className} ref={containerRef}>
      <span
        className="avatar-title rounded-circle align-middle"
        style={{ fontSize: Math.min(width, height) * 0.5 }}
      >
        {avatarLetters}
      </span>
    </div>
  );
};

interface OrganizationAvatarProps {
  imageURL?: string | undefined;
  organizationName?: string | undefined;
  size?: UserAvatarSize;
  className?: string;
  innerClassName?: string;
  isCircle?: boolean;
}

export const OrganizationAvatar = ({
  imageURL,
  organizationName,
  size = UserAvatarSize.normal,
  className = "",
  innerClassName = "",
  isCircle,
}: OrganizationAvatarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(containerRef.current?.offsetWidth ?? 0);
    setHeight(containerRef.current?.offsetHeight ?? 0);
  }, [containerRef]);

  return imageURL ? (
    <div className={"flex-shrink-0 avatar " + size + " " + className}>
      <img
        src={imageURL}
        className={classNames(
          "avatar-img",
          isCircle ? "rounded-circle" : "deprecated-rounded",
          innerClassName,
        )}
      />
    </div>
  ) : (
    <div className={"flex-shrink-0 avatar " + size + " " + className} ref={containerRef}>
      <span
        className={classNames(
          "avatar-title align-middle",
          isCircle ? "rounded-circle" : "deprecated-rounded",
          innerClassName,
        )}
        style={{ fontSize: Math.min(width, height) * 0.5 }}
      >
        {organizationName ? organizationName.charAt(0) : <span className="fe fe-briefcase" />}
      </span>
    </div>
  );
};

export const MergeMarkAvatar = () => {
  return (
    <div className="flex-shrink-0">
      <img src={MergeMark} width="48" height="48" alt="Merge logo" />
    </div>
  );
};
