import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AnimatedChevron from "../configuration/common-models/AnimatedChevron";
import { Accordion, AccordionContext, Form, useAccordionToggle } from "react-bootstrap";

const CardTop = styled.div`
  gap: 8px;
`;

const FormToggle = styled(Form)`
  transform: scale(0.7);
`;

const StyledAnimatedChevron = styled(AnimatedChevron)`
  margin-left: 4px;
  font-size: 10px;
  color: var(--lm-gray-50, #939aa5);
`;

interface SubLeftNavigationBarCardTopProps {
  eventKey: string;
  cardTitle: string;
  toggleId: string;
  isToggleChecked?: boolean;
  onToggleClick: (enabled: boolean) => void;
}
const SubLeftNavigationBarCardTop = ({
  eventKey,
  cardTitle,
  toggleId,
  isToggleChecked,
  onToggleClick,
}: SubLeftNavigationBarCardTopProps) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});
  const currentKey = useContext(AccordionContext);
  const isOpen = currentKey == eventKey;

  const [isToggleEnabled, setIsToggleEnabled] = useState<boolean | undefined>(undefined);
  const onToggle = () => {
    if (isToggleEnabled === undefined) return;

    const enabled = isToggleEnabled;
    onToggleClick(!enabled);
  };

  useEffect(() => {
    setIsToggleEnabled(isToggleChecked);
  }, [isToggleChecked]);

  const toggle =
    isToggleEnabled !== undefined ? (
      <FormToggle className="-mr-3">
        <Form.Check type="switch" id={toggleId} checked={isToggleEnabled} onClick={onToggle} />
      </FormToggle>
    ) : null;

  return (
    <CardTop className="px-4 py-3 d-flex flex-column align-items-start align-self-stretch">
      <div className="d-flex flex-row justify-content-sm-between w-100">
        <div className="text-sm font-semibold">{cardTitle}</div>
        {toggle}
      </div>
      <div className="clickable-icon" onClick={decoratedOnClick}>
        <div className="d-flex align-items-center flex-row">
          <div className="text-gray-50 font-normal text-xs">Details</div>
          <StyledAnimatedChevron $isUp={isOpen} />
        </div>
      </div>
    </CardTop>
  );
};

export interface SubLeftNavigationBarCardProps {
  cardTitle: string;
  cardDetails: JSX.Element | string;
  toggleId: string;
  isToggleChecked?: boolean;
  onToggleClick: (enabled: boolean) => void;
}

const SubLeftNavigationBarCard: React.FC<SubLeftNavigationBarCardProps> = ({
  cardTitle,
  cardDetails,
  toggleId,
  isToggleChecked,
  onToggleClick,
}: SubLeftNavigationBarCardProps) => {
  return (
    // Use Accordion so the card's expansion/collapse is smoothly animated
    <Accordion>
      <div className="bg-white d-flex flex-column align-items-start shadow-md rounded-lg">
        <SubLeftNavigationBarCardTop
          eventKey="0"
          cardTitle={cardTitle}
          toggleId={toggleId}
          isToggleChecked={isToggleChecked}
          onToggleClick={onToggleClick}
        />
        <Accordion.Collapse eventKey="0">
          <div className="border-t border-gray-10 py-2.5 px-4 font-normal text-xs">
            {cardDetails}
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default SubLeftNavigationBarCard;
