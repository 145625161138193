import React, { useState } from "react";
import ConfigurationCommonModelsPageWrapper from "../../../components/configuration/common-models/ConfigurationCommonModelsPageWrapper";
import INTEGRATION_CATEGORY_LIST from "../../../models/Helpers";
import { Redirect, Route, Switch } from "react-router-dom";
import { commonModelsConfigurationPathForIndividualCategory } from "../../../router/RouterUtils";
import { APICategory } from "../../../models/Entities";
import ConfigurationCommonModelsPage from "../../configuration/common-models/ConfigurationCommonModelsPage";
import ConfigurationCommonModelsUpsell from "./ConfigurationCommonModelsUpsell";

function ConfigurationCommonModelsFullPage() {
  const [showUpsellModal, setShowUpsellModal] = useState(false);

  return (
    <>
      <ConfigurationCommonModelsUpsell
        showUpsellModal={showUpsellModal}
        setShowUpsellModal={setShowUpsellModal}
      />

      <ConfigurationCommonModelsPageWrapper
        showUpsellModal={showUpsellModal}
        setShowUpsellModal={setShowUpsellModal}
      >
        <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
          <Switch>
            {INTEGRATION_CATEGORY_LIST.map((category: APICategory) => (
              <Route
                key={category}
                exact
                path={commonModelsConfigurationPathForIndividualCategory(category)}
              >
                <ConfigurationCommonModelsPage
                  category={category}
                  showUpsellModal={showUpsellModal}
                  setShowUpsellModal={setShowUpsellModal}
                />
              </Route>
            ))}
            <Redirect
              to={commonModelsConfigurationPathForIndividualCategory(INTEGRATION_CATEGORY_LIST[0])}
            />
          </Switch>
        </div>
      </ConfigurationCommonModelsPageWrapper>
    </>
  );
}

export default ConfigurationCommonModelsFullPage;
