import React from "react";
import styled, { css } from "styled-components";
import { palette, spectrum } from "../../../styles/theme";

type Highlighted = { isHighlighted: boolean };

/**
 * The styles to apply to something to make it look like plaintext within
 * the search bar.
 */
export const SEARCH_BAR_PLAINTEXT = css`
  font-size: 14px;
  line-height: 24px;
  color: ${palette.black};
  white-space: nowrap;
`;

// This isn't just the component that's returned as it needs an actual JSX element within it to properly keep highlighted state - probably from the key
const Row = styled.div<Highlighted>`
  padding: 6px 12px;
  ${SEARCH_BAR_PLAINTEXT};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${palette.blue};
      background-color: ${spectrum.blue0};
    `}
`;

/**
 * A simple row of text to use as a plain text row. Make sure to pass
 * children so it has something to display. To execute something when
 * clicked, pass `onClick`.
 */
const SearchBarPlaintextRow = ({
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & Highlighted) => (
  <Row {...props}>
    <span>{children}</span>
  </Row>
);

export default SearchBarPlaintextRow;
