import React from "react";
import OrganizationTeamMembers from "./OrganizationTeamMembers";
import ChangeMeUserOrganizationForm from "./ChangeOrganizationForm";
import OrganizationTwoFactorAuth from "./OrganizationTwoFactorAuth";
import PendingTeamMembers from "./PendingTeamMembers";

function OrganizationPage() {
  return (
    <>
      <ChangeMeUserOrganizationForm />
      <OrganizationTwoFactorAuth />
      <PendingTeamMembers />
      <OrganizationTeamMembers />
    </>
  );
}

export default OrganizationPage;
