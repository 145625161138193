import { useEffect, useState } from "react";
import { fetchWithoutAuth, getTenancy, Tenancy } from "../../../api-client/APIClient";

type SSOFeatureFlagsResponse = {
  disable_google_sso: boolean;
  disable_saml_sso: boolean;
};

type SSOFeatureFlags = {
  isGoogleSSODisabled: boolean;
  isSAMLSSODisabled: boolean;
};

function useSSOFeatureFlags(): SSOFeatureFlags {
  const isSingleTenant = getTenancy() == Tenancy.SingleTenant;
  const [isGoogleSSODisabled, setIsGoogleSSODisabled] = useState(isSingleTenant);
  const [isSAMLSSODisabled, setIsSAMLSSODisabled] = useState(isSingleTenant);
  useEffect(() => {
    fetchWithoutAuth({
      path: "/tenants/sso/feature-flags",
      method: "GET",
      onResponse: (data: SSOFeatureFlagsResponse) => {
        setIsSAMLSSODisabled(data.disable_saml_sso);
        setIsGoogleSSODisabled(data.disable_google_sso);
      },
    });
  }, []);

  return { isGoogleSSODisabled, isSAMLSSODisabled };
}

export default useSSOFeatureFlags;
