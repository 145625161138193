import React from "react";
import clsx from "clsx";
import SkeletonLoader from "../../../../../../shared-components/SkeletonLoader";

const WebhookSelectLoading = () => {
  const cardClasses = clsx(
    "bg-white cursor-default rounded-lg border border-gray-10 flex flex-col px-5 py-3 mt-4",
  );

  return (
    <>
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <div key={index} className={cardClasses}>
            <div className="flex flex-col gap-y-3">
              <SkeletonLoader width={160} height={12} borderRadius={4} />
              <SkeletonLoader width={342} height={12} borderRadius={4} />
            </div>
          </div>
        ))}
    </>
  );
};

export default WebhookSelectLoading;
