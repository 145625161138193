import { formatDistanceToNow, formatDistanceToNowStrict, sub } from "date-fns";
import { DATE_PICKER_OPTIONS } from "../../../constants";
import { INVOICE_FE_DISABLED_STATUSES } from "../../../models/Entities";
import { OVERDUE_INVOICE_DISABLED_ROUTES } from "../../../router/RouterUtils";
import { UserWithBillingPlan } from "../../context/AppContext";
import { isBillingPlanEnterpriseExpand } from "../../settings/billing/BillingUtils";
import { ProductRestrictions } from "../../settings/billing/BillingModels";

export const REGEX_EMAIL_ADDRESS =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Given a time string as returned by `formatDistanceToNowStrict`, replace units of time with abbreviations.
 * Example: "40 minutes" -> "40m"
 */
export const replaceDistanceWithShortFormat = (timestamp: string) => {
  // This is verbose but it was already written somewhere else and it works.
  if (timestamp) {
    if (timestamp.indexOf("seconds") > -1) {
      return timestamp.replace(" seconds", "s");
    } else if (timestamp.indexOf("second") > -1) {
      return timestamp.replace(" second", "s");
    } else if (timestamp.indexOf("minutes") > -1) {
      return timestamp.replace(" minutes", "m");
    } else if (timestamp.indexOf("minute") > -1) {
      return timestamp.replace(" minute", "m");
    } else if (timestamp.indexOf("hours") > -1) {
      return timestamp.replace(" hours", "h");
    } else if (timestamp.indexOf("hour") > -1) {
      return timestamp.replace(" hour", "h");
    } else if (timestamp.indexOf("days") > -1) {
      return timestamp.replace(" days", "d");
    } else if (timestamp.indexOf("day") > -1) {
      return timestamp.replace(" day", "d");
    } else if (timestamp.indexOf("months") > -1) {
      return timestamp.replace(" months", "mo");
    } else if (timestamp.indexOf("month") > -1) {
      return timestamp.replace(" month", "mo");
    } else if (timestamp.indexOf("years") > -1) {
      return timestamp.replace(" years", "y");
    } else if (timestamp.indexOf("year") > -1) {
      return timestamp.replace(" year", "y");
    }
    return timestamp;
  } else {
    return "unknown";
  }
};

export const getCapitalizedTimeFromNow = (timeAsString: string) => {
  const timeAgo = formatDistanceToNow(new Date(timeAsString)) + " ago";
  return timeAgo.charAt(0).toUpperCase() + timeAgo.slice(1);
};

export const getCapitalizedTimeFromNowShort = (timeAsString: string) => {
  const formattedTimestamp = replaceDistanceWithShortFormat(
    formatDistanceToNowStrict(new Date(timeAsString)),
  );
  const timeAgo = formattedTimestamp + " ago";
  return timeAgo.charAt(0).toUpperCase() + timeAgo.slice(1);
};

export function calculateDateFromPicker(selectedOption: string): Date | null {
  const today = new Date();
  switch (selectedOption) {
    case DATE_PICKER_OPTIONS.PAST_24_HOURS: {
      return sub(today, { days: 1 });
    }
    case DATE_PICKER_OPTIONS.PAST_WEEK: {
      return sub(today, { weeks: 1 });
    }
    case DATE_PICKER_OPTIONS.PAST_MONTH: {
      return sub(today, { months: 1 });
    }
    case DATE_PICKER_OPTIONS.PAST_YEAR: {
      return sub(today, { years: 1 });
    }
    default: {
      return null;
    }
  }
}

export function isRouteDisabled(user: UserWithBillingPlan, route: string): boolean {
  const dashboardDisabledForBilling =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  // add more logic here to disable more routes and tabs
  if (dashboardDisabledForBilling) {
    return OVERDUE_INVOICE_DISABLED_ROUTES.includes(route);
  }

  return false;
}

/**
 * Format date string to Mmm dd, YYYY
 * e.g. Feb 14, 2024
 */
export const formatDateToShortMonthDayYear = (dateString: string | undefined) => {
  if (dateString === undefined) {
    return "";
  }

  const shortMonth = (monthNumber: number) => {
    switch (monthNumber) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
      default:
        return `${monthNumber}`;
    }
  };

  const dateObj = new Date(dateString);
  return `${shortMonth(dateObj.getUTCMonth())} ${dateObj.getUTCDay()}, ${dateObj.getUTCFullYear()}`;
};
