import React from "react";
import styled from "styled-components";

const GreyDiv = styled.div`
  font-weight: 500;
  font-size: 10px;
  background-color: #eaeef3;
  padding: 0 4px;
  text-align: center;
  border-radius: 4px;
`;

export type SmallGreyBoxProps = {
  children?: string | number | JSX.Element;
};
const SmallGreyBox = ({ children }: SmallGreyBoxProps) => {
  return <GreyDiv className="d-flex align-items-center">{children}</GreyDiv>;
};
export default SmallGreyBox;
