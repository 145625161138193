import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";

export interface TileWithImageProps {
  integrationImage: React.ReactElement;
  sandboxName: string;
  categoryName: string;
  sandboxURL: string;
}

//used for the header in each of the individual step sections
const TitleText = styled(DeprecatedH5)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #111317;
  margin-bottom: 7px;
  margin-top: 8px;
`;

const CategoryText = styled(DeprecatedH5)`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #a3b0ca;
`;

const GhostDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// used for the primary description container
const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
  outline: none;
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--gray10);
    transition: ease-in-out all 0.15s;
  }
  margin-bottom: 15px;
`;

const Image = styled.i``;

const FinalRowContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
`;

const SandboxTile = ({
  integrationImage,
  sandboxName,
  categoryName,
  sandboxURL,
}: TileWithImageProps) => (
  <GhostDiv>
    <Link to={{ pathname: sandboxURL }} target="_blank" rel="noopener noreferrer">
      <Container>
        <Image>{integrationImage}</Image>
        <TitleText>
          <strong>{sandboxName}</strong> Sandbox
        </TitleText>
      </Container>
    </Link>
    <FinalRowContainer>
      <CategoryText>{categoryName}</CategoryText>
    </FinalRowContainer>
  </GhostDiv>
);

export default SandboxTile;
