import React from "react";
import styled from "styled-components";
import { PaddingLessCol } from "./LeftSideBarSpacing";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

export interface TileWithImageProps {
  img?: React.ReactElement;
  title: string | JSX.Element;
  subtitle?: JSX.Element;
  stepNumber: number;
}

export const TitleText = styled(DeprecatedH6)`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-top: 56px;
  margin-bottom: 17px;
  align-items: center;
  color: #121314;
`;

const MobileResponsiveText = styled(DeprecatedH6)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #121314;
  margin-bottom: 0px;
  margin-left: 17px;
  margin-top: 11px;
`;

const MobileResponsiveContainer = styled.div`
  display: none;
  @media (max-width: 1000px) {
    background-color: #f2f4f7;
    width: 100%;
    height: 42px;
    display: flex;
    align-self: start;
  }
`;

const MainContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  min-height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

const TitleCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

const Title = styled(DeprecatedH4)`
  width: 100%;
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--gray90);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  width: 100%;
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #000000;
  margin: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Image = styled.div`
  margin-left: 22px;
  svg {
    width: 100px;
    height: 100px;
  }
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

type DescriptionProps = Pick<React.ComponentProps<"div">, "className"> & {
  title: string | JSX.Element;
  subtitle?: JSX.Element;
};

const Description = ({ title, subtitle, className }: DescriptionProps) => (
  <DescriptionContainer className={className}>
    <TitleCtn>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleCtn>
  </DescriptionContainer>
);

/*
 ** This is the general step component used across all sections of the onboarding
 */

const TileStep = ({ img, title, subtitle, stepNumber }: TileWithImageProps) => (
  <PaddingLessCol>
    <MainContainer>
      <MobileResponsiveContainer>
        <MobileResponsiveText>Step {stepNumber}</MobileResponsiveText>
      </MobileResponsiveContainer>
      {img ? (
        <GridContainer>
          <Image>{img}</Image>
          <Description title={title} subtitle={subtitle} />
        </GridContainer>
      ) : (
        <Description title={title} subtitle={subtitle} />
      )}
    </MainContainer>
  </PaddingLessCol>
);

export default TileStep;
